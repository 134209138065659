import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "oh-noes-"
    }}>{`Oh noes! 🙈`}</h1>
    <p>{`It's a 404. That's a `}<em parentName="p">{`Page Not Found`}</em>{` error. Sorry about that.`}</p>
    <p>{`If you came here through a link, please send a message to `}<inlineCode parentName="p">{`dev@hel.fi`}</inlineCode>{` about where you came from (i.e. what was the address of the page that had the broken link).  Maybe we can fix things.`}</p>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Return to front page`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      